
import { Component, Vue, Watch } from 'vue-property-decorator';
import PageTitle from '@/ui-components/PageTitle/PageTitle.vue';
import MyCampaignsTabs from './components/MyCampaignsTabs.vue';
import { ResetAllCampaigns } from '@/store/modules/incoming-campaigns-module/types';
import namespaces from '@/store/namespaces';
import { Action } from 'vuex-class';
import { Route } from 'vue-router';
import { regulatorRouteNames } from '@/route-names/regulator';

@Component({
  name: 'MyCampaigns',
  components: { MyCampaignsTabs, PageTitle },
})
export default class MyCampaigns extends Vue {
  @Action('resetAllCampaigns', {
    namespace: namespaces.IncomingCampaignsModule,
  })
  public resetAllCampaigns!: ResetAllCampaigns;

  @Watch('$route')
  public routeChange(_: Route, to: Route) {
    if (
      to.name !== regulatorRouteNames.MY_CAMPAIGN_VIEW.name &&
      to.name !== regulatorRouteNames.MY_CAMPAIGNS.name
    ) {
      this.resetAllCampaigns();
    }
  }

  public created() {
    window.addEventListener('beforeunload', () => {
      this.resetAllCampaigns();
    })
  }
}
