
import { Component, Vue } from 'vue-property-decorator';
import SkeletonLoader from '@/ui-components/SkeletonLoader/SkeletonLoader.vue';

@Component({
  name: 'SkeletonLoading',
  components: {
    SkeletonLoader,
  },
})
export default class SkeletonLoading extends Vue {}
