
import { Component, Prop, Vue } from 'vue-property-decorator';
import TabItem from '@/ui-components/Tabs/TabItem.vue';
import SkeletonLoading from './SkeletonLoading.vue';
import NoCampaigns from '@/shared-components/NoCampaigns.vue';
import CompletedCampaignsTable from './CompletedCampaignsTable.vue';
import { CompletedCampaign } from '@/types/campaigns';

@Component({
  name: 'RunningCampaigns',
  components: {
    TabItem,
    NoCampaigns,
    SkeletonLoading,
    CompletedCampaignsTable,
  },
})
export default class CompletedCampaigns extends Vue {
  @Prop({ type: Array, default: () => [] })
  public campaigns!: CompletedCampaign[];

  @Prop({ type: Boolean })
  public loading!: boolean;

  @Prop({ type: String })
  public activeTab!: string;
}
