
import { Component, Vue, Watch } from 'vue-property-decorator';
import Tabs from '@/ui-components/Tabs/Tabs.vue';
import Tab from '@/ui-components/Tabs/Tab.vue';
import TabsItems from '@/ui-components/Tabs/TabsItems.vue';
import TabItem from '@/ui-components/Tabs/TabItem.vue';
import { regulatorIncomingCampaignsCategories } from '@/statics/incoming-campaigns';
import UpcomingCampaigns from './UpcomingCampaigns/UpcomingCampaigns.vue';
import RunningCampaigns from './RunningCampaigns/RunningCampaigns.vue';
import CompletedCampaigns from './CompletedCampaigns/CompletedCampaigns.vue';
import { Action, Getter } from 'vuex-class';
import namespaces from '@/store/namespaces';
import {
  LoadRegulatorCompletedCampaigns,
  LoadRegulatorRunningCampaigns,
  LoadStoreScreenClusters, LoadRegulatorUpcomingCampaigns,
  setActiveTab,
} from '@/store/modules/incoming-campaigns-module/types';
import {
  Campaign,
  CategorizedStorePendingCampaignsList,
} from '@/types/campaigns';
import { ScreenCluster } from '@/types/screen-cluster';

@Component({
  name: 'MyCampaignsTabs',
  components: {
    Tabs,
    Tab,
    TabsItems,
    TabItem,
    UpcomingCampaigns,
    RunningCampaigns,
    CompletedCampaigns,
  },
})
export default class MyCampaignsTabs extends Vue {
  @Action('loadRegulatorUpcomingCampaigns', {
    namespace: namespaces.IncomingCampaignsModule,
  })
  public loadRegulatorUpcomingCampaigns!: LoadRegulatorUpcomingCampaigns;

  @Action('loadRegulatorCompletedCampaigns', {
    namespace: namespaces.IncomingCampaignsModule,
  })
  public loadRegulatorCompletedCampaigns!: LoadRegulatorCompletedCampaigns;

  @Action('loadRegulatorRunningCampaigns', {
    namespace: namespaces.IncomingCampaignsModule,
  })
  public loadRegulatorRunningCampaigns!: LoadRegulatorRunningCampaigns;

  @Action('loadStoreScreenClusters', {
    namespace: namespaces.StoreModule,
  })
  public loadStoreScreenClusters!: LoadStoreScreenClusters;

  @Action('setActiveTab', {
    namespace: namespaces.IncomingCampaignsModule,
  })
  public setActiveTab!: setActiveTab;

  @Getter('upcomingCampaigns', {
    namespace: namespaces.IncomingCampaignsModule,
  })
  public upcomingCampaigns!: CategorizedStorePendingCampaignsList;

  @Getter('completedCampaigns', {
    namespace: namespaces.IncomingCampaignsModule,
  })
  public completedCampaigns!: Campaign[];

  @Getter('runningCampaigns', {
    namespace: namespaces.IncomingCampaignsModule,
  })
  public runningCampaigns!: Campaign[];

  @Getter('screenClusters', {
    namespace: namespaces.IncomingCampaignsModule,
  })
  public screenClusters!: ScreenCluster[];

  @Getter('activeTab', {
    namespace: namespaces.IncomingCampaignsModule,
  })
  public storeActiveTab!: string;

  public activeTab: string | null = this.storeActiveTab || null;
  public loading = false;

  public categories = regulatorIncomingCampaignsCategories;

  public tabsCategoriesActionsMap: { [key: string]: () => Promise<void> } = {
    upcoming_campaigns: this.loadRegulatorUpcomingCampaigns,
    completed_campaigns: this.loadRegulatorCompletedCampaigns,
    running_campaigns: this.loadRegulatorRunningCampaigns,
  };

  public async tabChanged(tabName: string) {
    this.setActiveTab(tabName)
    this.setLastVisitedTab();
    if (this.activeTab !== tabName) {
      this.$router.push({
        hash: tabName,
      });
    }

  }

  public async reloadData() {
    if (this.activeTab) {
      const action = this.tabsCategoriesActionsMap[this.activeTab];
      this.loading = true;
      await action();
      this.loading = false;
    }
  }

  public setLastVisitedTab() {
    this.activeTab = this.$route.hash.replace('#', '');
  }

  public async initData() {
    await this.loadStoreScreenClusters();
    this.setLastVisitedTab();
    await this.reloadData();
  }
  @Watch('$route')
  public routeChange() {
    this.initData();
  }

  public async created() {
    const isPageOpened = window.sessionStorage.getItem('pageOpened')
    if (!isPageOpened) {
      this.initData();
    } else {
      this.activeTab = this.storeActiveTab;
    }
  }
}
