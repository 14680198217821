import { render, staticRenderFns } from "./RunningCampaignsTable.vue?vue&type=template&id=13c92ad5&scoped=true"
import script from "./RunningCampaignsTable.vue?vue&type=script&lang=ts"
export * from "./RunningCampaignsTable.vue?vue&type=script&lang=ts"
import style0 from "./RunningCampaignsTable.vue?vue&type=style&index=0&id=13c92ad5&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13c92ad5",
  null
  
)

export default component.exports